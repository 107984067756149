


























































































import LandingSection from '@/components/LandingSection.vue'
import CurveWaterWaves from '@/components/CurveWaterWaves.vue'
import { defineComponent } from '@vue/composition-api'
import BaseBox from './_base/BaseBox.vue'
import BaseText from './_base/BaseText.vue'

export default defineComponent({
  name: 'LandingSectionSocialCommitment',

  components: {
    LandingSection,
    CurveWaterWaves,
    BaseBox,
    BaseText,
  },
})
